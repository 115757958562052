import { h, render, Component } from 'preact';
import { useEffect } from 'preact/hooks';
import Section from './components/Section.js'
import Select from './components/Select.js'
import Datepicker from './partials/Datepicker.js'
import Guests from './partials/Guests.js'
import Restaurants from './partials/Restaurants.js'
import SpaSelector from './partials/SpaSelector.js'
import SearchForm from './partials/SearchForm.js'

import { getBreakpoint } from './Utils.js'

class OneJourneySearch extends Component {
    constructor(props) {
        super();

        this.state = {
            action: props.action,
            hotelID: props.hotelID,
            searchType: props.searchTypes.length == 1 ? props.searchTypes[0]['key'] : null,
            searchSettings: props.searchTypes[0],
            changed: false,
            fieldValues: { ...props.fieldValues },
            invalid: null,
            filteredCategories: [],
            stage: null,
            roomDetails: null,
            maxGuests: props.searchTypes[0].maxAdults,
            roomID: props.roomID,
            rateCode: props.rateCode,
            promoRateCode: props.promoRateCode,
            restaurant: props.restaurant
        }

        this.connector = new OJ.DataConnector(props.hotelID)

        this.categories = []

        this.REQUIRED_FIELDS = [
            'searchType','fromDate','toDate','spaType'
        ]

        this.changeSearchType = this.changeSearchType.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.updateFieldValues = this.updateFieldValues.bind(this)
        this.updateStages = this.updateStages.bind(this)
        this.setActive = this.setActive.bind(this)
        this.changeSection = this.changeSection.bind(this)
        this.filterCategories = this.filterCategories.bind(this)
        this.selectSpaType = this.selectSpaType.bind(this)
        this.updateSearchDates = this.updateSearchDates.bind(this)
        this.sharedSectionProps = this.sharedSectionProps.bind(this)
    }

    listenForUpdates() {
        useEffect(() => {
            let updateState = (event) => {
                let state = event.detail,
                    fieldValues = {}

                if(state?.rateCode !== undefined
                || state?.promoRateCode !== undefined
                || state?.restaurant !== undefined) {
                    fieldValues = state
                }

                let searchType = "rooms"

                if(state?.rateCode || state?.promoRateCode) {
                    searchType = "rates"
                } else if(state?.restaurant) {
                    searchType = "dining"
                } else if(state?.searchType && state?.searchType != "all") {
                    searchType = state.searchType
                }

                this.changeSearchType({ "search-type": searchType })
                this.updateFieldValues(fieldValues, state, 'fromDate')
            }

            window.addEventListener('onejourneyUpdates', updateState)
        }, [])
    }

    componentDidMount() {
        let updates = {
            filteredCategories: this.connector.getCategories()
        }

        if(this.state.roomID) {
            updates.roomDetails = this.connector.getRoomDetails({
                roomID: this.state.roomID
            })
        }

        Promise.all(Object.values(updates)).then(responses => {

            responses.forEach((response, i) => {

                if(!response) return

                let updateKey = Object.keys(updates)[i]

                updates[updateKey] = response

                if(updateKey == 'filteredCategories') {
                    this.categories = response
                }
                if(updateKey == 'roomDetails') {
                    updates['maxGuests'] = response.max_occupancy
                }
            })

            this.setState(updates)
        })

        $(document).on('click', (event) => {
            if(!$(event.target).closest('.c-onejourney-search__section, [data-module=onejourney-overlay-button]').length && this.state.stage != null) {
                this.reset()
            }
        });
        $(document).on('oj_search_close', () => this.reset())
    }

    reset() {
        this.setState({
            stage: null
        })
    }

    changeSearchType(response) {
        let searchType = Object.values(response)[0]
        let searchSettings = this.props.searchTypes.filter((type) => type.key == searchType)[0] || this.props.searchTypes[0]
        let stage = searchSettings.fields[0]

        this.props.searchTypes.filter((type) => type.key == searchType)

        if(searchSettings.category_has) {
            this.filterCategories(searchSettings.category_has)
        }

        this.setState({
            searchType: searchType,
            searchSettings: searchSettings,
            changed: true,
            stage: stage,
            maxGuests: searchSettings.maxAdults > 0 ? searchSettings.maxAdults : Infinity,
            action: this.getAction(searchType)
        })
    }

    submitForm(event) {
        if(!this.checkFields(true)) {
            event.preventDefault()

            this.setState({
                invalid: true
            })
        } else {
            return true
        }
    }

    checkFields(bool) {

        let invalidFields = this.state.searchSettings.fields.filter((key) => {
            return this.REQUIRED_FIELDS.includes(key) && !this.state.fieldValues[key]
        })

        if(this.state.searchType == null) {
            invalidFields.push('searchType')
        }

        if(bool) {
            return invalidFields.length == 0
        } else {
            return invalidFields
        }
    }

    getAction(searchType) {
        let action = this.props.action

        switch (searchType) {
            case "rooms":
                action += `${searchType}${this.state.roomID ? '/' + this.state.roomID : ''}`
                break;

            case "rates":
                action += `${searchType}`
                break;

            case "dining":
                action += `tables/book`
                break;

            case "products":
                action += this.state.fieldValues.category ? `categories/${this.state.fieldValues.category}` : 'products'
                break;

            case "spa":
                action += "spa/" + (this.state.fieldValues.spaType == "treatments" ? "treatments" : "days")
                break;

            default:
                break;
        }

        return action
    }

    updateFieldValues(fieldValues, stateUpdates = {}, stage) {
        if(typeof stateUpdates == 'string') {
            stage = stateUpdates
            stateUpdates = null
        }

        this.setState(prevState => {
            let updates = {
                fieldValues: {...prevState.fieldValues, ...fieldValues},
                action: this.getAction(this.state.searchType)
            }

            Object.keys(fieldValues).forEach((field) => {
                if(updates.fieldValues[field] === false) delete updates.fieldValues[field]
            })

            if(stage) {
                let nextStage = this.getNextStage(stage, fieldValues)

                if(nextStage != null) updates['stage'] = nextStage
            }

            return {...updates, ...stateUpdates}
        })
    }

    getNextStage(stage, fieldValues) {
        let desktop = getBreakpoint() == 'desktop'
        let nextStage, stageIndex

        if(desktop && this.props.formVariation == 'vertical') {
            nextStage = false

            if(stage == 'guests')   nextStage = null
            if(stage == 'fromDate') nextStage = this.hasRequiredDates(fieldValues) ? false : null

            return nextStage
        } else {

            let stages = this.state.stages

            if(stage != 'fromDate' && stages) {
                stageIndex = stages.indexOf(stage)

                if(stageIndex == -1) nextStage = false

                let newStageIndex = stageIndex + 1
                nextStage = stages[newStageIndex] || null
            }

            return nextStage
        }
    }

    filterCategories(category, callback) {
        this.connector.getCategories(category).then((categories) => {
            this.setState({
                'filteredCategories': categories
            })
            if(callback) callback()
        })
    }

    selectSpaType(fieldValues) {
        this.filterCategories(Object.values(fieldValues)[0], () => {
            fieldValues['category'] = false
            this.updateFieldValues(fieldValues, 'spaType')
        })
    }

    hasRequiredDates(fieldValues) {
        let needsToDate = this.state.searchSettings.fields.includes('toDate')
        return needsToDate && fieldValues['toDate']?.submit || !needsToDate && fieldValues['fromDate']?.submit
    }

    updateSearchDates(fieldValues) {
        if(fieldValues['toDate'] && fieldValues['toDate'].submit != false && this.state.roomID && this.props.roomFromPrice?.dynamic) {

            let data = {
                roomID: this.state.roomID,
                fromDate: fieldValues['fromDate'].submit,
                toDate: fieldValues['toDate'].submit,
                adults: this.state.maxGuests,
                infants: 0
            }

            let stageUpdate = {}

            if(getBreakpoint() == 'desktop' && this.props.formVariation == 'vertical') {
                stageUpdate = {
                    'stage': this.getNextStage('fromDate', fieldValues)
                }
            }

            this.updateFieldValues(fieldValues, stageUpdate, 'fromDate')

            this.connector.getRoomDetails(data).then(roomDetails => {
                this.setState({
                    roomDetails: { ...roomDetails, ...data }
                })
            })

        } else {
            this.updateFieldValues(fieldValues, 'fromDate')
        }
    }

    setActive(section) {
        this.setState({
            stage: section
        })
    }

    updateStages(stages) {
        this.setState({
            stages: stages
        })
    }

    changeSection(event, direction) {
        event.stopPropagation()

        let stages = this.state.stages
        let section = stages.indexOf(this.state.stage)
        let sectionString = direction == 0 ? null : stages[section + direction]
        let multipleForms = $('.js-onejourney-overlay-forms').length

        if(sectionString == undefined) {
            if(multipleForms) {
                $('.js-onejourney-overlay-forms').removeClass('is-active')
                $('.js-onejourney-overlay-menu').addClass('is-active')
            }
            if(direction == 0 || !multipleForms) {
                $('body').removeClass('onejourney-search-overlay-enabled')
            }
        }

        this.setActive(sectionString)
    }

    sharedSectionProps(sectionStage) {
        let props = {
            changeSection: this.changeSection
        }

        if(sectionStage) {
            props['stage'] = sectionStage
            props['onClick'] = () => this.setActive(sectionStage)
            props['active'] = this.state.stage && this.state.stage == sectionStage
            props['invalid'] = this.state.invalid === true && this.checkFields().includes(sectionStage)
        }

        return props
    }

    render(props) {
        let settings = this.state.searchSettings
        let formFields = settings.fields
        let roomDetails = this.state.roomDetails
        let fieldValues = this.state.fieldValues
        let showPrices = ojAvailabilityRates.showPrices === 'true' && (this.state.searchType == 'rooms' && !this.state.roomID) || typeof fieldValues.rateCode != 'undefined' || typeof fieldValues.promoRateCode != 'undefined'
        let showAvailability = ojAvailabilityRates.enabled === 'true' && (this.state.searchType == 'rooms' || this.state.searchType == 'rates')

        let roomPrice = () => {
            let staticPrice = this.props.roomFromPrice.price
            let dynamicPrice = (roomDetails.price_per_night && fieldValues.fromDate && fieldValues.fromDate.submit == roomDetails.fromDate) ? roomDetails.price_per_night.formatted : false

            if(dynamicPrice || staticPrice) {
                return <h4 class="c-onejourney-search__form-subheading">From { dynamicPrice || staticPrice } pn</h4>
            } else {
                return ''
            }
        }

        if(props.type == "global") this.listenForUpdates()

        return <SearchForm
                action={ this.state.action }
                onSubmit={ this.submitForm }
                stage={ this.state.stage }
                updateStages={ this.updateStages }
                buttonText={ props.buttonText }
                pricingData={ showPrices }
            >

            { props.searchTypes.length > 1 && !this.state.roomID && !this.state.rateCode && !this.state.promoRateCode && !this.state.restaurant &&
                <Section dialog={ props.searchPlaceholder } variation="selector" {...this.sharedSectionProps("searchType")} >
                    <Select
                        placeholder={
                            [<svg xmlns='http://www.w3.org/2000/svg' width='14.6' height='14.6'><path d='M6 1.5A4.5 4.5 0 1 0 10.5 6 4.5 4.5 0 0 0 6 1.5M6 0a6 6 0 1 1-6 6 6 6 0 0 1 6-6Z'/><path d='m13.1 14.5-3.7-4 1-1 3.8 4Z'/></svg>,
                            props.searchPlaceholder]
                        }
                        options={ props.searchTypes.map((type) => {
                            return {
                                'key': type['key'],
                                'value': type['label'],
                                'image': type['image']
                            }
                        })}
                        onChange={ this.changeSearchType }
                        required={ true }
                        active={ this.state.stage == 'searchType' }
                        value={ this.state.searchType }
                        submit={ false }
                    />
                </Section>
            }

            { roomDetails &&
                <Section variation="static" {...this.sharedSectionProps()}>
                    <div class="c-onejourney-search__form-headings" onClick={ () => this.setActive('fromDate') }>
                        <h3 class="c-onejourney-search__form-heading">{ roomDetails.title }</h3>
                        { roomPrice() }
                    </div>
                </Section>
            }

            { formFields.includes('fromDate') &&
                <Section dialog={ "When would you like to " + (this.searchType == "rooms" ? "stay?" : "visit?") } variation={ ["dates", ... formFields.includes('toDate') ? ["toDate"] : []] } {...this.sharedSectionProps("fromDate")}>
                    <Datepicker
                        toggleToDate={ formFields.includes('toDate') }
                        showPrices={ showPrices }
                        showAvailability={ showAvailability }
                        rateCode={ fieldValues.rateCode || fieldValues.promoRateCode }
                        onValueChange={ this.updateSearchDates }
                        currentValues={{
                            fromDate: fieldValues.fromDate,
                            toDate: fieldValues.toDate
                        }}
                        active={ this.state.stage == 'fromDate' }
                        onNextClick={ this.changeSection }
                        hotelID={ props.hotelID }
                        type={ this.state.searchType }
                        {...this.props.datepickerProps}
                    />
                </Section>
            }

            { formFields.includes('restaurant') && !props.fieldValues?.restaurant &&
                <Section dialog="Where would you like to eat?" {...this.sharedSectionProps("restaurant")}>
                    <Restaurants onValueChange={ (values) => this.updateFieldValues(values, 'restaurant') } active={ this.state.stage == 'restaurant' } value={ fieldValues.restaurant }/>
                </Section>
            }

            { formFields.includes('spaType') &&
                <Section dialog="What are you looking for?" variation="spaType" {...this.sharedSectionProps("spaType")}>
                    <SpaSelector categories={ this.categories } onValueChange={ this.selectSpaType } active={ this.state.stage == 'spaType' } value={ fieldValues.spaType }/>
                </Section>
            }

            { formFields.includes('category') &&
                <Section dialog="What are you looking for?" {...this.sharedSectionProps("category")}>
                    <Select
                        label="Category"
                        placeholder="All categories"
                        options={ this.state.filteredCategories }
                        name="category"
                        disabled={
                            fieldValues.spaType == null && this.state.searchType != 'products'
                        }
                        onChange={ (values) => this.updateFieldValues(values, 'category') }
                        active={ this.state.stage == 'category' }
                        value={ fieldValues.category }
                    />
                </Section>
            }

            { formFields.includes('adults') &&
                <Section dialog="Who's coming?" variation="guests" {...this.sharedSectionProps("guests")}>
                    <Guests
                        adultDefinition={ props.adultDefinition }
                        maxAdults={ this.state.maxGuests }
                        childDefinition={ props.childDefinition }
                        maxChildren={ settings.maxChildren > 0 || !formFields.includes('children') ?  Math.min(settings.maxChildren, this.state.maxGuests) : Infinity }
                        onValueChange={ (values, stageUpdate) => this.updateFieldValues(values, stageUpdate ? 'guests' : {}) }
                        currentValues={{
                            adults: fieldValues.adults,
                            infants: fieldValues.infants
                        }}
                        active={ this.state.stage == 'guests' }
                        {...this.props.guestsProps}
                    />
                </Section>
            }

            { this.state.stages &&
                <div class="c-onejourney-search__launcher">

                    { roomDetails &&
                        <h3 class="c-onejourney-search__launcher-text">
                            { roomDetails.title }
                        </h3>
                    }

                    <button type="button" onClick={ (e) => { e.stopPropagation(); this.setActive(this.state.stages[0]) } }>
                        { props.searchTypes.length > 1 ? props.searchPlaceholder : props.buttonText }
                    </button>

                </div>
            }

            { typeof fieldValues.rateCode != 'undefined' &&
                <input type="hidden" name="rateCode" value={ fieldValues.rateCode }/>
            }

            { typeof fieldValues.promoRateCode != 'undefined' &&
                <input type="hidden" name="promoRateCode" value={ fieldValues.promoRateCode }/>
            }

        </SearchForm>

    }
}

export default OneJourneySearch