import { h, render, Component } from 'preact';
import { setModalOverflow } from '../Utils.js'

class Select extends Component {
    constructor(props) {
        super();

        this.state = {
            value: null,
            showOptions: false
        }

        this.onChange = this.onChange.bind(this)
    }

    onChange(event) {
        let input = event ? event.currentTarget.querySelector('input') : null

        if(input && !input.checked) return false

        this.setState({
            value: input ? input.value : null,
            showOptions: false
        })

        if(this.props.onChange){
            this.props.onChange({
                [this.props.name]: input ? input.value : null
            })
        }
    }

    reset(emit = true) {
        if(emit){
            this.onChange(false)
        }
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.options) != JSON.stringify(this.props.options)){
            this.reset(false)
        }
    }

    getFieldClasses(value) {
       return  "c-onejourney-search__field" +
                (this.props.name != "search-type" ? " c-onejourney-search__field--select" : "") +
                (this.props.required ? " is-required" : "") +
                (value ? " is-valid is-complete" : "") +
                (this.props.disabled ? " is-disabled" : "")
    }

    render(props) {

        let value = this.state.value || props.value
        let selected = props.options ? props.options.filter((opt) => opt['key'] && opt['key'] == value)[0] : false

        return <div class={ this.getFieldClasses(value) }>

                <div class="c-onejourney-search__field-text">
                    <span class="c-onejourney-search__field-label">{ props.label }</span>
                    <p class="c-onejourney-search__field-placeholder">{ props.placeholder }</p>
                    <p class="c-onejourney-search__field-value">{ selected ? selected['value'] : '' }</p>
                    <span class="c-onejourney-search__field-clear" onClick={ () => this.reset() }></span>
                </div>

                <div class="c-onejourney-search__field-options" style={ !props.active || props.disabled ? "display: none" : "" } ref={modal => setModalOverflow(modal)}>
                    { props.options && props.options.map((option, i) => {
                        return <label class={"c-onejourney-search__field-option" + (value == option['key'] ? " is-active" : "")} onClick={ this.onChange }>
                                <p class="c-onejourney-search__field-option-label">{ option['value'] }</p>
                                <input type="radio" name={ props.submit === false ? '' : props.name } value={ option['key'] } checked={ value == option['key'] }/>

                                { option['image'] &&
                                    <img class="c-onejourney-search__field-image" src={ option['image'] } />
                                }
                            </label>
                    })}

                    <button type="button" class="c-onejourney-search__field-close" onClick={ () => $(document).trigger('oj_search_close') }><span>Close</span></button>
                </div>
            </div>

    }
}

export default Select