import { h, render, Component } from 'preact';

class Section extends Component {
    constructor(props) {
        super();

        this.toggle = this.toggle.bind(this)
        this.scrollIntoView = this.scrollIntoView.bind(this)
    }

    toggle(event) {
        let target = $(event.target)
        if(target.parents('.c-onejourney-search__field-text').length > 0 || target.hasClass('c-onejourney-search__field-text')) {
            if(this.props.onClick) this.props.onClick()
        }
    }

    scrollIntoView() {
        let options = this.section.querySelector('.c-onejourney-search__date-input, .c-onejourney-search__field-options')

        if(options.getBoundingClientRect().bottom > window.innerHeight) {
            options.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
        }
    }

    componentDidUpdate() {
        if(this.props.active) this.scrollIntoView()
    }

    render(props) {
        let variations = props.variation instanceof Array ? props.variation : [props.variation]

        return <section class={ "c-onejourney-search__section" + (variations.filter(v => v).map((variation) => { return " c-onejourney-search__section--" + variation}).join(' ')) + (props.active ? " is-active" : "") + (props.invalid ? " is-invalid" : "")} onClick={ this.toggle } ref={section => this.section = section}>

            <div class="c-onejourney-search__section-dialog">
                <button type="button" class="c-onejourney-search__section-prev" onClick={ (event) => props.changeSection(event, -1) }><span>Back</span></button>
                <h3>{ props.dialog }</h3>
                <button type="button" class="c-onejourney-search__section-close" onClick={ (event) => props.changeSection(event, 0) }><span>Close</span></button>
            </div>

            { props.children }
        </section>

    }
}

export default Section